<template>
  <base-template current-page="NotificationIndex">
    <router-view></router-view>
  </base-template>
</template>

<script>
import BaseTemplate from "../BaseTemplate";

export default {
  name: "NotificationIndex",
  components: { BaseTemplate },
};
</script>
